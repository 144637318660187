import {
  LandingPageB2BFragment,
  LandingPageFragment,
  Maybe
} from '@api/contentful';
import Tracked from '@components/Tracked';
import ButtonActions from '@lib/rudderstack/ButtonActions';
import { createButtonClickedSpec } from '@lib/rudderstack/EventSpecs';
import RichText from '@utils/RichText';

import * as S from './FaqSection.styled';

export interface FaqSectionProps {
  faqTitle:
    | Maybe<LandingPageFragment['faqSectionTitle']>
    | Maybe<LandingPageB2BFragment['faqSectionTitle']>;
  faqItems:
    | Maybe<LandingPageFragment['faqSectionItemsCollection']>
    | Maybe<LandingPageB2BFragment['faqSectionItemsCollection']>;
  greyBackground?: boolean;
}

const FaqSection = ({
  faqTitle,
  faqItems,
  greyBackground = true
}: FaqSectionProps) => {
  return (
    <S.OuterWrapper $greyBackground={greyBackground}>
      <S.Container $greyBackground={greyBackground}>
        <S.LeftContainer>
          <S.Title variant="h3" element="h3">
            {faqTitle}
          </S.Title>
        </S.LeftContainer>

        <S.Content>
          {faqItems?.items.map((item, index) => (
            <Tracked
              key={index}
              event={createButtonClickedSpec(
                ButtonActions.LANDING_FAQ_ACCORDION_CLICKED,
                { accordion: item?.title || '' }
              )}
            >
              <S.FAQCollapse
                $greyBackground={greyBackground}
                header={item?.title}
              >
                <RichText json={item?.text?.json} />
              </S.FAQCollapse>
            </Tracked>
          ))}
        </S.Content>
      </S.Container>
    </S.OuterWrapper>
  );
};

export default FaqSection;
