import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import ArrowLink from '@components/ArrowLink';
import RichText from '@utils/RichText';

export const Container = styled(C.Stack)`
  position: relative;
  background-color: ${({ theme }) => theme.palette.xLightGrey};
  padding-bottom: ${({ theme }) => theme.spacing[64]};
  overflow: hidden;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-top: ${theme.spacing[64]};
        padding-left: ${theme.spacing[16]};
        padding-right: ${theme.spacing[16]};
        padding-bottom: ${theme.spacing[32]};
      `
    )}
`;

export const CardOuter = styled.div<{ $flipped: boolean }>`
  background-color: transparent;
  perspective: 1000px;

  ${({ $flipped }) =>
    $flipped &&
    `
        ${CardInner} {
            transform: rotateY(180deg);
        }
    `}
`;

export const CardInner = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition('transform').pop.lg};
  transform-style: preserve-3d;
`;

export const CardsWrapper = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[32]};
  flex-direction: row;
  align-items: stretch;
  padding-top: ${({ theme }) => theme.spacing[56]};
  z-index: ${({ theme }) => theme.zIndex[2]};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        flex-direction: column;
      `
    )}
`;

const CardFace = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.secondary};
  backface-visibility: hidden;
`;

export const StyledArrowLink = styled(ArrowLink)`
  &:hover {
    text-decoration: underline;
  }
`;

export const CardFront = styled(CardFace)`
  color: ${({ theme }) => theme.palette.petrol};
  background-color: ${({ theme }) => theme.palette.snow};

  & * {
    color: ${({ theme }) => theme.palette.petrol};
  }

  & ${StyledArrowLink} * {
    color: ${({ theme }) => theme.palette.orange};
  }
`;

export const CardBack = styled(CardFace)`
  background-color: ${({ theme }) => theme.palette.petrol};
  color: ${({ theme }) => theme.palette.snow};
  transform: rotateY(180deg);

  & * {
    color: ${({ theme }) => theme.palette.snow};
  }

  & ${StyledArrowLink} * {
    color: ${({ theme }) => theme.palette.orange};
  }
`;

export const CardIconWrapper = styled(C.Inline)<{ $altBorder: boolean }>`
  width: fit-content;
  padding: ${({ theme }) => theme.spacing[8]};
  margin: ${({ theme }) => theme.spacing[24]};
  border: ${({ theme, $altBorder }) =>
    $altBorder ? theme.borders.primaryM : theme.borders.regularM};
  border-radius: 6px;
  transition: border-color 0.5s ease;
  background-color: ${({ theme, $altBorder }) =>
    $altBorder ? 'none' : theme.palette.snow};
`;

export const CardIcon = styled.img`
  aspect-ratio: 1;
  width: 20px;
`;

export const CardContent = styled(C.Inline)`
  padding-left: ${({ theme }) => `${theme.spacing[24]}`};
  padding-right: ${({ theme }) => `${theme.spacing[24]}`};
`;

export const CardTitle = styled(C.Typography)`
  display: block;
  color: inherit;
`;

export const CardDescription = styled(C.Typography)`
  margin: ${({ theme }) => `${theme.spacing[16]} 0`};
  display: block;
`;

const CardButton = styled(C.IconButton)<{ $isFlippedB2B?: boolean }>`
  background-color: ${({ theme, $isFlippedB2B }) =>
    $isFlippedB2B ? theme.palette.snow : theme.palette.primary};

  &:hover {
    background-color: ${({ theme, $isFlippedB2B }) =>
      $isFlippedB2B ? theme.palette.snow : theme.palette.primary};
  }
`;

export const ArrowButton = styled(CardButton)`
  &:hover,
  &.hovered {
    & > * {
      transform: rotate(-45deg);
    }
  }
`;

export const CloseButton = styled(CardButton)``;

export const CardRichText = styled(RichText)<{ $isFlipped?: boolean }>`
  ul {
    li {
      & > span {
        display: inline-block;
        vertical-align: middle;
      }
      list-style-image: ${({ $isFlipped }) =>
        $isFlipped
          ? "url('/checkmark-orange.svg')"
          : "url('/checkmark-petrol.svg')"};
      padding-left: ${({ theme }) => theme.spacing[16]};
      transition-delay: 280ms;
    }
  }
`;
