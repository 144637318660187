interface Props {
  className?: string;
}

const ArrowPattern = ({ className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1025"
    height="258"
    className={className}
  >
    <g fill="#FFF" fillRule="nonzero">
      <path
        d="M896 78.202a61.441 61.441 0 0 0-24.819 5.39c-7.865 3.45-14.115 8.279-18.753 14.484a34.85 34.85 0 0 0-6.962 21.454V129h-27.404v-9.302c-.004-7.018 1.193-13.035 3.591-18.05a34.489 34.489 0 0 1 10.895-12.893c4.869-3.589 11.122-6.283 18.759-8.084V78.2H768a61.441 61.441 0 0 0-24.819 5.39c-7.865 3.451-14.115 8.28-18.753 14.485a34.85 34.85 0 0 0-6.962 21.454V129h-27.404v-9.302c-.004-7.018 1.193-13.035 3.591-18.05a34.489 34.489 0 0 1 10.895-12.893c4.869-3.589 11.122-6.283 18.759-8.084V78.2h-84.154v.07h-9.436a35.215 35.215 0 0 0-21.485 6.937c-6.263 4.618-11.042 10.848-14.324 18.683-3.283 7.836-5.158 16.458-5.158 25.109H561.5c-.048-8.526-2.144-17.33-5.637-25.109-3.439-7.835-8.25-14.063-14.433-18.683a34.726 34.726 0 0 0-21.377-6.936h-9.435V50.97h9.268c6.993-.005 12.988 1.188 17.985 3.577a34.363 34.363 0 0 1 12.847 10.854c3.576 4.851 6.26 11.081 8.055 18.69h2.46V.097h27.305V84.09h2.46c2.835-11.488 7.423-19.88 13.765-25.177 6.342-5.296 14.735-7.944 25.18-7.944l9.057-.001v-.17h84.307v-2.47c-11.53-2.845-19.954-7.45-25.27-13.815-5.317-6.364-7.975-14.788-7.975-25.27V0h27.404v9.47a35.34 35.34 0 0 0 6.962 21.563c4.635 6.286 10.888 11.082 18.753 14.376 7.865 3.294 16.136 5.39 24.819 5.39h83.307v-2.47c-11.53-2.845-19.954-7.45-25.27-13.815-5.317-6.364-7.975-14.788-7.975-25.27V0h27.404v9.47a35.34 35.34 0 0 0 6.962 21.563c4.635 6.286 10.888 11.082 18.753 14.376 7.865 3.294 16.136 5.39 24.819 5.39v-.265h9.47a35.34 35.34 0 0 0 21.563-6.962c6.286-4.635 11.082-10.888 14.376-18.753 3.294-7.865 5.39-16.136 5.39-24.819h27.403a61.441 61.441 0 0 0 5.39 24.819c3.45 7.865 8.279 14.115 14.484 18.753a34.85 34.85 0 0 0 21.454 6.962h9.47v27.404h-9.302c-7.018.004-13.035-1.193-18.05-3.591a34.489 34.489 0 0 1-12.893-10.895c-3.589-4.869-6.283-11.122-8.084-18.759h-2.47L974 129h-27.25l.048-84.307h-2.469c-2.845 11.53-7.45 19.954-13.815 25.27-6.364 5.317-14.788 7.975-25.27 7.975H896v.264Z"
        opacity=".45"
      />
      <path d="M690.477 257h27.191v-9.396a34.58 34.58 0 0 1 6.908-21.288c4.602-6.158 10.804-10.949 18.608-14.373a60.965 60.965 0 0 1 24.626-5.347v-27.192c-8.616 0-16.823-2.079-24.626-5.347-7.804-3.269-14.009-8.028-18.608-14.264a35.066 35.066 0 0 1-6.908-21.397V129h-27.191v9.173c0 10.4 2.637 18.758 7.912 25.074 5.275 6.315 13.633 10.884 25.074 13.707v2.45H639.81v27.192h83.653v2.45c-7.577 1.786-13.782 4.46-18.613 8.02a34.221 34.221 0 0 0-10.81 12.794c-2.38 4.976-3.568 10.946-3.563 17.91V257ZM946.667 257h27.191v-9.396a34.58 34.58 0 0 1 6.908-21.288c4.601-6.158 10.804-10.949 18.608-14.373a60.965 60.965 0 0 1 24.626-5.347v-27.192c-8.616 0-16.823-2.079-24.626-5.347-7.804-3.269-14.009-8.028-18.608-14.264a35.066 35.066 0 0 1-6.908-21.397V129h-27.191v9.173c0 10.4 2.637 18.758 7.912 25.074 5.275 6.315 13.633 10.884 25.074 13.707v2.45H896v27.192h83.653v2.45c-7.578 1.786-13.782 4.46-18.614 8.02a34.221 34.221 0 0 0-10.81 12.794c-2.38 4.976-3.567 10.946-3.562 17.91V257Z" />
      <path d="m639.815 206.599.005-27.194-9.67.129a34.85 34.85 0 0 1-21.453-6.962c-6.206-4.638-11.035-10.888-14.486-18.753A61.441 61.441 0 0 1 588.822 129h-27.404c0 8.683-2.095 16.954-5.389 24.819-3.294 7.865-8.09 14.118-14.376 18.753a35.34 35.34 0 0 1-21.563 6.962h-9.47v27.404h9.244c10.482 0 18.906-2.658 25.27-7.975 6.365-5.316 10.97-13.74 13.815-25.27h2.47V258h27.403v-84.307h2.47c1.8 7.637 4.495 13.89 8.083 18.76a34.489 34.489 0 0 0 12.893 10.894c5.015 2.398 11.032 3.595 18.05 3.59l9.497-.338ZM767.8 179.405l-.009 27.2 9.416.253a34.58 34.58 0 0 1 21.287 6.908c6.158 4.601 10.949 10.804 14.373 18.608A60.965 60.965 0 0 1 818.214 257h27.192c0-8.616 2.079-16.823 5.347-24.626 3.269-7.804 8.028-14.009 14.264-18.608a35.066 35.066 0 0 1 21.397-6.908l9.602-.272.004-27.169-9.382.25c-10.401 0-18.76 2.637-25.075 7.912-6.315 5.275-10.884 13.633-13.707 25.074h-2.45V129h-27.192v83.653h-2.45c-1.786-7.578-4.46-13.782-8.02-18.614a34.221 34.221 0 0 0-12.794-10.81c-4.976-2.38-10.946-3.567-17.91-3.562l-9.24-.262ZM180.477 257h27.191v-9.396a34.58 34.58 0 0 1 6.908-21.288c4.602-6.158 10.804-10.949 18.608-14.373a60.965 60.965 0 0 1 24.626-5.347v-27.192c-8.616 0-16.823-2.079-24.626-5.347-7.804-3.269-14.009-8.028-18.608-14.264a35.066 35.066 0 0 1-6.908-21.397V129h-27.191v9.173c0 10.4 2.637 18.758 7.912 25.074 5.275 6.315 13.633 10.884 25.074 13.707v2.45H129.81v27.192h83.653v2.45c-7.577 1.786-13.782 4.46-18.613 8.02a34.221 34.221 0 0 0-10.81 12.794c-2.38 4.976-3.568 10.946-3.563 17.91V257Z" />
      <path d="M436.667 257h27.191v-9.396a34.58 34.58 0 0 1 6.908-21.288c4.601-6.158 10.804-10.949 18.608-14.373A60.965 60.965 0 0 1 514 206.596v-27.192c-8.616 0-16.823-2.079-24.626-5.347-7.804-3.269-14.009-8.028-18.608-14.264a35.066 35.066 0 0 1-6.908-21.397V129h-27.191v9.173c0 10.4 2.637 18.758 7.912 25.074 5.275 6.315 13.633 10.884 25.074 13.707v2.45H386v27.192h83.653v2.45c-7.578 1.786-13.782 4.46-18.614 8.02a34.221 34.221 0 0 0-10.81 12.794c-2.38 4.976-3.567 10.946-3.562 17.91V257ZM129.814 206.596l.002-27.188-9.666.126a34.85 34.85 0 0 1-21.453-6.962c-6.206-4.638-11.035-10.888-14.486-18.753A61.441 61.441 0 0 1 78.822 129H51.418c0 8.683-2.095 16.954-5.389 24.819-3.294 7.865-8.09 14.118-14.376 18.753a35.34 35.34 0 0 1-21.563 6.962H.62v27.404h9.244c10.482 0 18.906-2.658 25.27-7.975 6.365-5.316 10.97-13.74 13.815-25.27h2.47V258h27.403v-84.307h2.47c1.8 7.637 4.495 13.89 8.083 18.76a34.489 34.489 0 0 0 12.893 10.894c5.015 2.398 11.032 3.595 18.05 3.59l9.496-.341Z" />
      <path d="m257.795 179.421.003 27.19 9.409.247a34.58 34.58 0 0 1 21.287 6.908c6.158 4.601 10.949 10.804 14.373 18.608A60.965 60.965 0 0 1 308.214 257h27.192c0-8.616 2.079-16.823 5.347-24.626 3.269-7.804 8.028-14.009 14.264-18.608a35.066 35.066 0 0 1 21.397-6.908l9.587-.259-.008-27.196-9.355.264c-10.401 0-18.76 2.637-25.075 7.912-6.315 5.275-10.884 13.633-13.707 25.074h-2.45V129h-27.192v83.653h-2.45c-1.786-7.578-4.46-13.782-8.02-18.614a34.221 34.221 0 0 0-12.794-10.81c-4.976-2.38-10.946-3.567-17.91-3.562l-9.245-.246Z" />
    </g>
  </svg>
);

export default ArrowPattern;
