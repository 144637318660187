import { NextSeoProps } from 'next-seo';

import { Maybe, SeoFragment } from '@api/contentful';

import { META_IMAGE_URL } from './constants';
import { generateAbsoluteURL, removeQueryParamsFromURL } from './stringUtils';

export const formatContentfulSeoToNextSeo = (
  title: Maybe<string>,
  contentfulSeo?: Omit<SeoFragment, 'sys'> | null,
  pathname?: string
): NextSeoProps => {
  const ALLOWED_LOCALES =
    process.env.NEXT_PUBLIC_ALLOWED_LOCALES?.split(',') || [];

  const {
    description,
    keywords,
    canonical,
    noindex,
    nofollow,
    twitterCard,
    twitterSite,
    openGraphImage,
    openGraphTitle,
    openGraphDescription,
    openGraphType,
    openGraphUrl
  } = contentfulSeo || {};

  const nextSeo: NextSeoProps = {
    title: title ?? ' ',
    titleTemplate: '%s | Dribe',
    description: description ?? undefined
  };

  const sanitizeURL = (): string => {
    const url = canonical ?? pathname; // Fallback if no canonical entered into Contentful
    return url === '/' ? '' : removeQueryParamsFromURL(url);
  };

  if (keywords) {
    nextSeo.additionalMetaTags = [
      { property: 'keywords', content: keywords.join(', ') }
    ];
  }

  // Dummy proof if relative path in Contentful is typed with or without "/".
  nextSeo.canonical = generateAbsoluteURL(sanitizeURL());

  // Support for hreflang links based on the canonical tag and available locales
  nextSeo.languageAlternates = ALLOWED_LOCALES?.map(allowedLocale => {
    return {
      hrefLang: allowedLocale,
      href: generateAbsoluteURL(sanitizeURL(), allowedLocale, true)
    };
  });

  /* <!-- Only allow indexing if NEXT_PUBLIC_ENABLE_CRAWLERS environment variable is set to TRUE in production --> */
  nextSeo.noindex = process.env.NEXT_PUBLIC_ENABLE_CRAWLERS ? !!noindex : true;
  nextSeo.nofollow = process.env.NEXT_PUBLIC_ENABLE_CRAWLERS
    ? !!nofollow
    : true;

  /* <!-- Twitter specific tags--> */
  nextSeo.twitter = {
    cardType: twitterCard ?? undefined,
    site: twitterSite ?? undefined
  };

  /* <!-- OpenGraph specific tags--> */
  const images = [
    {
      url: openGraphImage?.url ?? META_IMAGE_URL,
      width: openGraphImage?.width ?? undefined,
      height: openGraphImage?.height ?? undefined,
      alt: openGraphImage?.title ?? undefined,
      type: openGraphImage?.contentType ?? undefined
    }
  ];

  nextSeo.openGraph = {
    images,
    title: openGraphTitle ?? undefined,
    description: openGraphDescription ?? undefined,
    type: openGraphType ?? undefined,
    url: openGraphUrl ?? undefined
  };

  return nextSeo;
};
