import React, { useEffect, useRef, useState } from 'react';

import { Maybe } from '@api/contentful';
import { useWindowSize } from '@hooks/useWindowSize';

import * as S from './HeroTitle.styled';

type HeroTitleProps = {
  title: Maybe<string>;
  isItalic?: boolean;
  className?: string;
};

const HeroTitle = ({ isItalic = true, title, className }: HeroTitleProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const { width } = useWindowSize();
  const [fontShift, setFontShift] = useState(1);
  const [finished, setFinished] = useState(false);
  const [divHeightOrig, setDivHeightOrig] = useState<number | null>(null);

  useEffect(() => {
    // Attempt at shifting font-size up/down to be at a maximum of two lines with the largest possible font-size
    if (divRef.current) {
      const divHeight = divRef.current.offsetHeight;
      setDivHeightOrig(divHeight);
      const computedStyle = window.getComputedStyle(divRef.current);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      // Don't round when the UI is non mobile
      const lines =
        width && width <= 768
          ? Math.round(divHeight / lineHeight)
          : divHeight / lineHeight;
      if (lines > 2) {
        setFontShift(prevState => prevState - 1);
      } else if (lines <= 2 && fontShift !== 1 && divHeight === divHeightOrig) {
        setFontShift(prevState => prevState + 1);
      } else {
        setFinished(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontShift, width]);
  return (
    <S.HeroTitle
      className={className}
      ref={divRef}
      as={'h1'}
      $finished={finished}
      $fontShift={fontShift}
      $isItalic={isItalic}
    >
      {title}
    </S.HeroTitle>
  );
};

export default HeroTitle;
