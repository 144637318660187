import { ReactElement } from 'react';

import { Icon, Inline, Stack } from '@dribe-io/ui-kit/components';
import { IconProps } from '@dribe-io/ui-kit/components/Icon/Icon';

import { Document } from '@contentful/rich-text-types';

import ButtonActions from '@lib/rudderstack/ButtonActions';
import { IsB2B } from 'types/response';

import * as S from './Card.styled';

type CardSideProps = {
  isFront: boolean;
  title: string;
  description?: Document;
  footer?: ReactElement | string;
  iconName?: string;
  action?: ButtonActions;
  isFlip?: boolean;
  isHover?: boolean;
  isFlipped?: boolean;
  visibleUrlTitle?: string;
  visibleUrl?: string;
} & IsB2B;

const CardSide = ({
  isFront,
  title,
  description,
  footer,
  iconName,
  isFlip = false,
  isHover = false,
  isFlipped,
  visibleUrlTitle,
  visibleUrl,
  isB2B
}: CardSideProps) => {
  const formatDescription =
    typeof description == 'string' ? (
      <S.CardDescription variant="body1">{description}</S.CardDescription>
    ) : (
      <Stack my={16} flex={1}>
        <S.CardRichText json={description} $isFlipped={isFlipped} />
      </Stack>
    );

  const buttonLink = isFront ? (
    <S.ArrowButton
      className={isHover ? 'hovered' : ''}
      iconName={isFlip ? 'flip' : 'arrowRightThin'}
      iconStyle={{ color: isFlip ? (isB2B ? 'secondary' : 'petrol') : 'snow' }}
    />
  ) : (
    <S.CloseButton
      $isFlippedB2B={isFlipped && isB2B}
      iconStyle={{ color: 'petrol' }}
      iconName="close"
    />
  );

  const card = (
    <>
      <S.CardIconWrapper $altBorder={!isFront && !isB2B}>
        <Icon
          size={28}
          name={iconName as IconProps['name']}
          color={'primary'}
        />
      </S.CardIconWrapper>
      <S.CardContent flexDirection={'column'} flex={1}>
        <S.CardTitle variant="h4" element="h4">
          {title}
        </S.CardTitle>
        {formatDescription}
        {!!visibleUrl && !!visibleUrlTitle && (
          <S.StyledArrowLink linkText={visibleUrlTitle} href={visibleUrl} />
        )}
      </S.CardContent>

      <Inline
        justifyContent="space-between"
        mt={32}
        p={24}
        alignX="flex-end"
        alignY="flex-end"
      >
        <Stack>{footer}</Stack>
        {buttonLink}
      </Inline>
    </>
  );

  return isFront ? (
    <S.CardFront>{card}</S.CardFront>
  ) : (
    <S.CardBack>{card}</S.CardBack>
  );
};

export { CardSide };
