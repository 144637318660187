import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

import { CommercialBackground } from '@theme/backgrounds';
import { contentPaddingHorizontal, contentWidthBoundary } from '@theme/layout';

export const OuterWrapper = styled.div<{ $greyBackground: boolean }>`
  ${contentPaddingHorizontal}
  background: ${({ theme, $greyBackground }) =>
    $greyBackground ? theme.palette.xLightGrey : theme.palette.snow};
`;

export const Container = styled(C.Inline)<{ $greyBackground: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding-top: ${({ theme }) => theme.spacing[104]};
  padding-bottom: ${({ theme }) => theme.spacing[128]};
  gap: ${({ theme }) => theme.spacing[72]};
  background: ${({ theme, $greyBackground }) =>
    $greyBackground ? CommercialBackground.FullGrey : theme.palette.snow};
  ${contentWidthBoundary}

  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        flex-direction: column;
        gap: ${theme.spacing[32]};
      `
    )}

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-top: ${theme.spacing[32]};
        padding-bottom: ${theme.spacing[104]};
        gap: ${theme.spacing[24]};
      `
    )}

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      `
        padding-top: ${theme.spacing[16]};
      `
    )}
`;

export const LeftContainer = styled(C.Inline)`
  order: 0;
  flex: 1;
  justify-content: flex-start;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        justify-content: center;
      `
    )}
`;

export const Content = styled(C.Inline)`
  order: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[24]};
  flex: 2;
  align-items: stretch;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        gap: ${theme.spacing[16]};
        order: 0;
      `
    )};
`;

export const Title = styled(C.Typography)`
  font: ${({ theme }) => theme.typography.h2};
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-top: ${theme.spacing[64]};
        padding-bottom: ${theme.spacing[16]};
        font: ${theme.typography.h4}
      `
    )}
`;

export const FAQCollapse = styled(C.Collapse)<{
  $greyBackground: boolean;
}>`
  background-color: ${({ theme, $greyBackground }) =>
    $greyBackground ? theme.palette.snow : theme.palette.xxLightGrey};
  border-radius: 10px;
  flex: 1;
  & .collapse-content {
    padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]}`};
  }
`;
