import { apiObject } from 'rudder-sdk-js';

import ButtonActions from '@lib/rudderstack/ButtonActions';
import {
  createButtonClickedSpec,
  createLinkClickedSpec
} from '@lib/rudderstack/EventSpecs';
import { trackEvent } from '@lib/rudderstack/index';
import LinkTitles from '@lib/rudderstack/LinkTitles';
import {
  TrackButtonEventDataMap,
  TrackLinkEventDataMap
} from '@lib/rudderstack/types';

export const trackButton = <T extends ButtonActions>(
  type: T,
  data: TrackButtonEventDataMap[T] = null
) => trackEvent(createButtonClickedSpec(type, data as apiObject));

export const trackLink = <T extends LinkTitles>(
  type: LinkTitles,
  destination: string,
  data: TrackLinkEventDataMap[T] = null
) => trackEvent(createLinkClickedSpec(type, destination, data as apiObject));

export { ButtonActions, LinkTitles };
