export const BLURRED_IMAGE_URL = '/blurred-ride-loading.webp';

export const META_IMAGE_URL = `${process.env.NEXT_PUBLIC_SITE_URL}/dribe-share.png`;

export const FALLBACK_MIN_PRICE = '100';
export const FALLBACK_MAX_PRICE = '1000000';
export const FALLBACK_LOCALE = 'en';

export const SITE_B2B = 'Business';
export const SITE_B2C = 'Private';

export const FUNCTIONAL_COOKIE_CONSENT_ID = 'C0003';
