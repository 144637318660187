import { Feature } from '@api/contentful';
import { Card } from '@components/Card';
import ButtonActions from '@lib/rudderstack/ButtonActions';
import { IsB2B } from 'types/response';

import * as S from './FeatureCards.styled';

type FeatureCardsProps = {
  features?: Feature[];
  className?: string;
  showArrowPattern?: boolean;
} & IsB2B;

export const FeatureCards = ({
  features,
  isB2B,
  className,
  showArrowPattern = true
}: FeatureCardsProps) => {
  return (
    <S.Container className={className}>
      <S.CardsWrapper justifyContent="center">
        {features &&
          features.map((feature, index) => (
            <Card
              key={`${feature.sys.id}_${index}`}
              isB2B={isB2B}
              frontTitle={feature.title || ''}
              frontDescription={feature.defaultDescription?.json}
              frontIconName={feature.defaultIcon?.iconName || ''}
              backTitle={feature.flipTitle || ''}
              backDescription={feature.flipDescription?.json}
              backIconName={feature.flipIcon?.iconName || ''}
              action={ButtonActions.LANDING_FEATURE_CARD_CLICKED}
              frontUrlTitle={feature.visibleLinkTitle || ''}
              frontUrl={feature.visibleLink || ''}
              backUrlTitle={feature.flipVisibleLinkTitle || ''}
              backUrl={feature.flipVisibleLink || ''}
            />
          ))}
      </S.CardsWrapper>
      {showArrowPattern && <S.SVGPattern />}
    </S.Container>
  );
};
