import styled from 'styled-components';

const transformFontSize = (originalFontSize: number, fontShift: number) => {
  return `${originalFontSize + fontShift}px`;
};

export const HeroTitle = styled.div<{
  $fontShift: number;
  $finished: boolean;
  $isItalic?: boolean;
}>`
  text-transform: uppercase;
  overflow: hidden;
  opacity: ${({ $finished }) => ($finished ? 1 : 0)};
  hyphens: none;
  color: ${({ theme }) => theme.palette.petrol};
  white-space: pre-wrap;
  font: ${({ theme }) => theme.typography.h1};
  font-style: ${({ $isItalic }) => ($isItalic ? 'italic' : 'normal')};
  font-size: ${({ $fontShift }) => transformFontSize(80, $fontShift)};
  ${({ theme, $fontShift }) =>
    theme.breakpoints.down(
      'xl',
      `
        font-size: ${transformFontSize(60, $fontShift)};
        line-height: 90px;

      `
    )};
  ${({ theme, $fontShift }) =>
    theme.breakpoints.down(
      'lg',
      `
          font-size: ${transformFontSize(48, $fontShift)};
          line-height: 72px;
        `
    )};
  ${({ theme, $fontShift }) =>
    theme.breakpoints.down(
      'md',
      `
        font-size: ${transformFontSize(48, $fontShift)};
          line-height: 72px;
        `
    )};
  ${({ theme, $fontShift }) =>
    theme.breakpoints.down(
      'sm',
      `
          min-height: 0%;
          font-size: ${transformFontSize(32, $fontShift)};
          line-height: 48px;
        `
    )};
`;
