import React, { ReactElement, useState } from 'react';

import { useRouter } from 'next/router';

import { FeatureDefaultDescription, Maybe } from '@api/contentful';
import {
  trackButton,
  trackLink,
  LinkTitles,
  ButtonActions
} from '@lib/rudderstack/trackers';
import { IsB2B } from 'types/response';

import * as S from './Card.styled';
import { CardSide } from './CardSide';

export type CardProps = {
  frontTitle: string;
  frontDescription?: Maybe<FeatureDefaultDescription['json']>;
  frontFooter?: ReactElement | string;
  frontIconName?: string;
  frontUrlTitle?: string;
  frontUrl?: string;
  backTitle?: string;
  backDescription?: Maybe<FeatureDefaultDescription['json']>;
  backFooter?: ReactElement | string;
  backIconName?: string;
  backUrlTitle?: string;
  backUrl?: string;
  link?: string;
  action?: ButtonActions;
  hidden?: boolean;
} & IsB2B;

export const Card = ({
  frontTitle,
  frontDescription,
  frontFooter,
  frontIconName,
  frontUrlTitle,
  frontUrl,
  backTitle,
  backDescription,
  backFooter,
  backIconName = frontIconName,
  backUrlTitle,
  backUrl,
  link,
  hidden,
  isB2B
}: CardProps) => {
  const router = useRouter();
  const [isFlipped, setIsFlipped] = useState(false);
  const [isHover, setIsHover] = useState(false);
  if (hidden) return null;

  const toggleFlip = () => {
    const trackOptions = {
      card: frontTitle,
      isFront: !isFlipped
    };

    if (!link) {
      trackButton(ButtonActions.LANDING_FEATURE_CARD_CLICKED, trackOptions);
      setIsFlipped(!isFlipped);
    } else {
      trackLink(LinkTitles.CARD_LINK, link, trackOptions);
      router.push(link);
    }
  };

  const cardHover = () => {
    setIsHover(true);
  };

  const cardNotHover = () => {
    setIsHover(false);
  };

  const frontSide = (
    <CardSide
      isB2B={isB2B}
      isFront={true}
      title={frontTitle}
      description={frontDescription}
      footer={frontFooter}
      iconName={frontIconName}
      isFlip={!link}
      isHover={!!isHover}
      isFlipped={isFlipped}
      visibleUrlTitle={frontUrlTitle}
      visibleUrl={frontUrl}
    />
  );

  const backSide = (
    <CardSide
      isB2B={isB2B}
      isFront={false}
      title={backTitle || ''}
      description={backDescription}
      footer={backFooter}
      iconName={backIconName}
      isFlip={!link}
      isHover={!!isHover}
      isFlipped={isFlipped}
      visibleUrlTitle={backUrlTitle}
      visibleUrl={backUrl}
    />
  );

  return (
    <S.CardOuter $flipped={isFlipped}>
      <S.CardInner
        onClick={toggleFlip}
        onMouseOver={cardHover}
        onMouseOut={cardNotHover}
      >
        {frontSide}
        {backSide}
      </S.CardInner>
    </S.CardOuter>
  );
};
