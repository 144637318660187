import * as C from '@dribe-io/ui-kit/components';

import styled, { css } from 'styled-components';

import ArrowPattern from '@components/ArrowPattern';
import { contentPaddingHorizontal } from '@theme/layout';

export const Container = styled(C.Stack)`
  position: relative;
  ${contentPaddingHorizontal}
  padding-bottom: ${({ theme }) => theme.spacing[160]};
  overflow: hidden;
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      `
        padding-top: ${theme.spacing[64]};
        padding-bottom: ${theme.spacing[16]};
      `
    )}
`;

export const CardsWrapper = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[32]};
  flex-direction: row;
  align-items: stretch;
  z-index: ${({ theme }) => theme.zIndex[2]};
  width: 100%;
  & > * {
    flex: 1;
  }
  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        flex-direction: column;
      `
    )}
`;

export const SVGPattern = styled(ArrowPattern)`
  position: absolute;
  right: 0;
  bottom: ${({ theme }) => `-${theme.spacing[48]}`};

  ${({ theme }) =>
    theme.breakpoints.down(
      'md',
      css`
        right: -250px;
      `
    )}
`;
