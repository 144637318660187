import styled from 'styled-components';

import { MAX_WIDTH } from '@theme/layout';
import RichText from '@utils/RichText';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Banner = styled.div`
  padding-block: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.palette.snow};
  width: ${MAX_WIDTH};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    theme.breakpoints.down(
      'lg',
      `
        padding-inline: ${theme.spacing[8]};
        text-align: center;
        word-break: keep-all;
        hyphens: none;
      `
    )};
`;

export const BannerText = styled(RichText)<{ $isB2B?: boolean }>`
  & p,
  & p > span,
  & > span,
  & > div {
    color: white;
    font: ${({ theme }) => theme.typography.body2};
  }
  & .rich-text-bold {
    font: ${({ theme }) => theme.typography.body2};
    font-family: 'GTFlexa-Bd';
  }
  a * {
    color: ${({ theme, $isB2B }) =>
      $isB2B ? theme.palette.orange : theme.palette.petrol};
    cursor: pointer;
    :hover {
      color: ${({ theme, $isB2B }) =>
        $isB2B ? theme.palette.tertiaryOrange : theme.palette.tertiaryPetrol};
    }
  }
`;
