import React from 'react';

import { Document } from '@contentful/rich-text-types';

import { IsB2B } from 'types/response';

import * as S from './Banner.styled';

type BannerProps = {
  content: Document;
  className?: string;
} & IsB2B;

const Banner = ({ content, isB2B, className }: BannerProps) => {
  return (
    <S.Wrapper className={className}>
      <S.Banner>
        <S.BannerText $isB2B={isB2B} json={content} />
      </S.Banner>
    </S.Wrapper>
  );
};

export default Banner;
