import * as C from '@dribe-io/ui-kit/components';

import styled from 'styled-components';

export const ArrowLinkContainer = styled(C.Inline)`
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const LinkArrow = styled(C.IconButton)<{
  $goBack: boolean;
}>`
  order: ${({ $goBack }) => ($goBack ? '0' : '1')};
  // TODO: Need to refactor IconButton styling to use Styled Components
  background-color: transparent !important;
`;
